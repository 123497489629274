<template>
  <div class="Factory MERCH">
    <div class="merch-card">
      <div class="mess-box">
        <div class="mess-title">会员信息</div>
        <div class="mess">
          <div>
            <div>
              <div class="mess-lable nomargin">单位名称：</div>
              <div>
                {{ UserLoanUserInfoData.enterprise || "/" }}
              </div>
            </div>
            <div>
              <div class="mess-lable nomargin">授信额度：</div>
              <div v-if="UserLoanUserInfoData.creditLine">{{ (UserLoanUserInfoData.creditLine*1).toFixed(2)  || "/" }}</div>
            </div>
          </div>
          <div>
            <div>
              <div class="mess-lable">会员类型：</div>
              <div>{{ UserLoanUserInfoData.type || "/" }}</div>
            </div>
            <div>
              <div class="mess-lable">剩余额度：</div>
              <div v-if="UserLoanUserInfoData.availableCredit">{{ (UserLoanUserInfoData.availableCredit*1).toFixed(2) || "/" }}</div>
            </div>
          </div>
          <div>
            <div>
              <div class="mess-lable">所在地址：</div>
              <div v-if="UserLoanUserInfoData.province">
                {{ UserLoanUserInfoData.province }}-{{
                  UserLoanUserInfoData.city
                }}-{{ UserLoanUserInfoData.district }}
              </div>
              <div v-else>/</div>
            </div>
            <div>
              <div class="mess-lable">首次开通平台：</div>
              <div>{{ UserLoanUserInfoData.firstPlatFromName || "/" }}</div>
            </div>
          </div>
          <div>
            <div>
              <div class="mess-lable">证件号码：</div>
              <div>{{ UserLoanUserInfoData.idCard || "/" }}</div>
            </div>
            <div>
              <div class="mess-lable">开通时间：</div>
              <div>{{ UserLoanUserInfoData.startDate || "/" }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="mess-box">
        <div class="mess-title title-bor">贷款信息</div>
        <div class="mess">
          <div>
            <div>
              <div class="mess-lable nomargin">借据号：</div>
              <div>{{ UserLoanListData.loanBalanceNo || "/" }}</div>
            </div>
            <div>
              <div class="mess-lable nomargin">贷款金额：</div>
              <div v-if="UserLoanListData.putoutAmount">{{ (UserLoanListData.putoutAmount*1).toFixed(2) || "/" }}</div>
            </div>
          </div>
          <div>
            <div>
              <div class="mess-lable">贷款期限：</div>
              <div v-if="UserLoanListData.loanTerms">
                {{ UserLoanListData.loanTerms }}月
              </div>
              <div v-else>/</div>
            </div>
            <div>
              <div class="mess-lable">还款日期：</div>
              <div>{{ UserLoanListData.repayDay || "/" }}</div>
            </div>
          </div>
          <div>
            <div>
              <div class="mess-lable">贷款利率：</div>
              <div>{{ UserLoanListData.loanIntRate || "/" }}</div>
            </div>
            <div>
              <div class="mess-lable">还款方式：</div>
              <div v-if="UserLoanListData.returnType == 1">等额还款</div>
              <div v-if="UserLoanListData.returnType == 2">等本还款</div>
              <div v-if="UserLoanListData.returnType == 3">利随本清</div>
              <div v-if="UserLoanListData.returnType == 4">净息还款</div>
              <!-- <div v-else>/</div> -->
            </div>
          </div>
          <div>
            <div>
              <div class="mess-lable">放款日期：</div>
              <div>{{ UserLoanListData.putoutDay || "/" }}</div>
            </div>
            <div>
              <div class="mess-lable">订单号：</div>
              <div>{{ UserLoanListData.orderGroupId || "/" }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 列表 -->

      <div>
        <div class="mess-title title-bor">还款信息</div>
        <div class="table-box">
          <el-table
            :data="UserLoanItemData"
            border
            style="width: 100%"
            height="380"
            :header-cell-style="{ background: '#F2F3F5' }"
          >
            <!-- <el-table-column
              label="序号"
              align="center"
              prop="index"
              width="60"
            >
            </el-table-column> -->
            <el-table-column prop="address" label="请求时间" align="center">
              <template slot-scope="{ row }">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.requestDate"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.requestDate }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              label="已还本金（元）"
              align="center"
            >
              <template slot-scope="{ row }">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.principalPaid"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.principalPaid }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              label="已还利息（元）"
              align="center"
            >
              <template slot-scope="{ row }">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.interestPaid"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.interestPaid }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="贷款到期日" align="center">
              <template slot-scope="{ row }">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.maturityDate"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.maturityDate }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              label="剩余本金（元）"
              align="center"
            >
              <template slot-scope="{ row }">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.remainingPrincipal"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.remainingPrincipal }}</div>
                </el-tooltip>
              </template>
            </el-table-column>

            <el-table-column
              prop="address"
              label="剩余利息（元）"
              align="center"
            >
              <template slot-scope="{ row }">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.residualInterest"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.residualInterest }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              label="本次还款金额（元）"
              align="center"
            >
              <template slot-scope="{ row }">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.amountSubmitted"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.amountSubmitted }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="类型" align="center" width="100">
              <template slot-scope="{ row }">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.repayType"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.repayType | repayType}}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="状态" align="center" width="100">
              <template slot-scope="{ row }">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.repayStatus"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.repayStatus | repayStatus}}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              label="平台贴息（元）"
              align="center"
            >
              <template slot-scope="{ row }">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="(row.platDiscount*1).toFixed(2)"
                  placement="bottom"
                >
                  <div class="exceed">{{ (row.platDiscount*1).toFixed(2) }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              label="会员付息（元）"
              align="center"
            >
              <template slot-scope="{ row }">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="(row.memIntPay*1).toFixed(2)"
                  placement="bottom"
                >
                  <div class="exceed">{{ (row.memIntPay*1).toFixed(2) }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <!-- <el-table-column
              prop="address"
              label="应还贴息（元）"
              align="center"
            >
              <template slot-scope="{ row }">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.curPlatDiscount"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.curPlatDiscount }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              label="实还贴息（元）"
              align="center"
            >
              <template slot-scope="{ row }">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.actPlatDiscount"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.actPlatDiscount }}</div>
                </el-tooltip>
              </template>
            </el-table-column> -->
          </el-table>
        </div>
      </div>
      <!-- 分页 -->
      <!-- <div class="merch-page">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :page-size="usersData.per_page"
          layout="total, prev, pager, next, jumper"
          :total="usersData.total"
          :current-page="searchApi.page"
        >
        </el-pagination>
      </div> -->
      <div class="footer">
        <div @click="goback">返回</div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const { mapState, mapActions } = createNamespacedHelpers("userLoan"); //vuex公共库
export default {
  data() {
    return {
      UserLoanUserInfoData: {}, //会员信息
      UserLoanListData: {}, //贷款信息
      UserLoanItemData: [], //还款记录
    };
  },

  computed: {
    ...commonIndex.mapState([
      "AreaList",
      "userType",
      "mainHright",
      "allJurisdiction",
      "detailsSeach",
      "userType",
    ]),
  },
  filters: {
    repayType(v) {
      switch (v) {
        case '1':
          return "还款";
        case '2':
          return "退款";
      }
    },
    repayStatus(v) {
      switch (v) {
        case 'SUCCESS':
          return "成功";
        case 'FAIL':
          return "失败";
      }
    },
  },
  created() {
    let userInfoId = this.$route.query.userId;
    let number = this.$route.query.number;
    this.UserLoanUserInfo(userInfoId);
    this.UserLoanList(userInfoId, number);
    this.UserLoanItem(this.$route.query.number);
    // this.toSearch();
  },
  mounted() {
    this.tableHei = this.mainHright * 1 + 50;
    console.log(this.tableHei);
  },
  methods: {
    ...mapActions([
      "getUserLoanUserInfo",
      "postUserLoanList",
      "getUserLoanItem",
    ]),
    goback() {
      history.go(-1);
    },
    // 获取会员信息
    async UserLoanUserInfo(val) {
      let res = await this.getUserLoanUserInfo(val);
      if (res.code == "000000") {
        this.UserLoanUserInfoData = res.content;
      } else {
        this.$message({
          message: res.message,
          type: "warning",
        });
        return;
      }
    },
    // 获取贷款信息
    async UserLoanList(val, event) {
      let params = {
        userId: val,
        number: event,
      };
      let res = await this.postUserLoanList(params);
      if (res.code == "000000") {
        this.UserLoanListData = res.content.list[0];
      } else {
        this.$message({
          message: res.message,
          type: "warning",
        });
        return;
      }
    },
    // 还款记录
    async UserLoanItem(val) {
      let res = await this.getUserLoanItem(val);
      if (res.code == "000000") {
        this.UserLoanItemData = res.content;
      } else {
        this.$message({
          message: res.message,
          type: "warning",
        });
        return;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.dio-input {
  // .el-input {
  //   width: 300px !important;
  // }
  .el-upload--picture-card {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
}
.el-tabs__item.is-active {
  color: #06b7ae;
}
.el-tabs__item:hover {
  color: #06b7ae;
}
.el-tabs__active-bar {
  background-color: #06b7ae;
}
.el-input-group__prepend {
  padding: 0px 0px 0px 5px !important;
}
.el-input__inner {
  border-radius: 4px !important;
}
.el-range-separator {
  width: 20% !important;
}
</style>
<style lang="scss" scoped>
.userBtn {
  display: flex;
  justify-content: center;
  div {
    margin-left: 5px;
    color: #1abca9;
    cursor: pointer;
  }
}
.MERCH {
  width: 100%;
  height: 100%;
  .merch-card {
    width: 100%;
    max-height: 100%;
    padding: 20px;
    padding-top: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    background: #ffffff;
    .merch-tag {
      width: 100%;
      height: 40px;
    }
    // 搜索行样式
    .search {
      display: flex;
      .search-box1 {
        width: 250px;
        margin-right: 20px;
      }
      .search-btn {
        width: 80px;
        height: 40px;
        line-height: 40px;
        background-color: #06b7ae;
        color: #fff;
        text-align: center;
        border-radius: 4px;
        cursor: pointer;
      }
    }
    .merch-table {
      margin-top: 1%;
      width: 100%;
      height: 77%;
    }
    .merch-page {
      margin-top: 10px;
      width: 100%;
      display: flex;
      align-content: center;
      justify-content: right;
    }
  }
}
::v-deep .el-select {
  width: 100%;
}
.table-box {
  margin-top: 20px;
}
.mess {
  display: flex;
  justify-content: space-between;
  font-size: 14px !important;
  color: #666;
  padding: 0 10px;
  > div {
    flex: 1;
    > div {
      margin: 10px 0;
      display: flex;

      div {
        color: #222;
        line-height: 20px;
      }
      .mess-lable {
        white-space: nowrap;
        margin-left: 40px;
        color: #666;
      }
      .nomargin {
        margin-left: 0;
      }
    }
  }
}
.mess-title {
  font-size: 16px;
  font-weight: 600;
  padding-top: 13px;
}
.mess-title::before {
  width: 4px;
  height: 16px;
  background-color: #06b7ae;
  border-radius: 10px;
  display: inline-block;
  content: "";
  margin-right: 10px;
  margin-bottom: -2px;
}
.title-bor {
  padding-top: 30px;
  border-top: 1px dashed rgba($color: #000000, $alpha: 0.3);
  margin-top: 15px;
}
.footer {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  div {
    text-align: center;
    width: 60px;
    line-height: 32px;
    background-color: #06b7ae;
    border-radius: 4px;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
  }
}
</style>
